<template>
  <div class="admin-feed-block">
    <AdminStatisticsFilter :statistics="statistics" :title="$locale['credits_packages']" />

    <div :class="`statistics-block __general ${loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          :icon="`coins`"
          :label="`<div>${$locale.words['credit-bag']} ${$locale['of']} <strong>${Go.number_format(bag.obtain)}</strong></div>`"
          :desc="
            `<go-progress skeleton progress='${getPercent(bag.numBags, statistics.totalBags)}' 
            color='${bag.color || '#bd0909'}'></go-progress>`
          "
          :rightDesc="`<strong skeleton>${number_format(bag.numBags)}</strong>`"
          @click="setQuery({ admin: 'statistics-credits-details', startDate, endDate, period: 'custom' })"
          prevent
          v-for="(bag, index) in bags"
          :key="index"
          :bag="bag"
        />
      </div>
    </div>

    <div :class="`statistics-block __general ${loading ? '__loading' : '__loaded'}`" statistics.totalBags>
      <div class="ReportInfo">
        <AdminStatisticsSalesBagsChart :bags="bags" />
      </div>
    </div>
    <Spacer num=".5" />
  </div>
</template>

<script>
import AdminStatistics from "./AdminStatistics";
export default {
  mixins: [AdminStatistics],
  components: {
    AdminStatisticsFilter: () => import("./AdminStatisticsFilter.vue"),
    AdminStatisticsSalesBagsCard: () => import("./AdminStatisticsSalesBagsCard.vue"),
    AdminStatisticsSalesBagsChart: () => import("./AdminStatisticsSalesBagsChart.vue"),
    LastPeriodFilter: () => import("../widgets/LastPeriodFilter.vue"),
  },
  data: function() {
    return {
      loading: true,
    };
  },
  methods: {
    openFilter: function() {
      this.$refs.context.open();
    },
    setPeriod: function(data) {
      const { period, startDate, endDate } = typeof data === "string" ? { period: data } : data;
      this.setQuery({ period, startDate, endDate });
    },
    getQuery: function({ page }) {
      const { period, startDate, endDate } = this.$route.query;
      let query = `?period=${period}&page=${page < 1 ? 1 : page}`;
      query += `&startDate=${startDate}&endDate=${endDate}`;
      return query;
    },
    getStatistics: async function({ page }) {
      this.isLoading(true);
      try {
        const query = this.getQuery({ page });
        const response = await this.$api.get(`${process.env.VUE_APP_API_HOST}admin/statistics-bags${query}`);
        this.$store.commit("setAdminBagsStatistics", { ...response.data });
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
    getPercent: function(mount, total) {
      return Math.round((mount / total) * 100);
    },
  },
  mounted: function() {
    this.isLoading(true);
    setTimeout(() => {
      this.getStatistics({ page: 1 });
    }, 1000);
  },
  computed: {
    lang: function() {
      return this.$locale.statistics;
    },
    period: function() {
      return this.$route.query.period || "last-6-months";
    },
    periodLabel: function() {
      const fromDate = this.$global.dateLocaleFormat(this.statistics.startDate);
      const toDate = this.$global.dateLocaleFormat(this.statistics.endDate);
      return `${fromDate}, ${toDate}`;
    },
    bags: function() {
      return this.$store.getters.admin.bags.items;
    },
    statistics: function() {
      return this.$store.getters.admin.bags.statistics;
    },
  },
};
</script>
